<template>
  <b-container
    fluid
  />
</template>
<script>
import { core } from '@/config/pluginInit'
import { SharedListService } from '@/services'

export default {
  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  mounted () {
    core.index()

    SharedListService.getBudgetCenters().then(() => {
      // (data)
    })
  }
}
</script>
